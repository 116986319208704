@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans&display=swap');


@font-face {
  font-family: 'SF Pro Display Regular';
  src: local('SF Pro Display Regular'), url(/src/assets/font/SFProDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: local('SF Pro Display Bold'), url(/src/assets/font/SFProDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Italic';
  src: local('SF Pro Display Italic'), url(/src/assets/font/SFProDisplay-Italic.ttf) format('truetype');
}

* {
  font-family: 'SF Pro Display Regular', 'PT sans', serif !important;
}

.font-bold {
  font-family: 'SF Pro Display Bold', 'PT sans', serif !important;
}

.font-italic {
  font-family: 'SF Pro Display Italic', 'PT sans', serif !important;
}

:root {
  --pardelia-primary: #7540EE !important;
  --pardelia-blue: #2F61D5 !important;
  --pardelia-violet: #956af2 !important;
  --pardelia-yellow: #cb9b4a !important;

  --pardelia-primary-medium: #6139ca !important;
  --pardelia-primary-strong: #25265E !important;

  --gray: #6c757d !important;
  --gray-light: #c0c3c5 !important;
  --gray-background: #fafafb !important;
  --danger: #dc3545;


  --pardelia-violet-background: #e4dcfc !important;


  --lateral-menu-width: 73px;
  --speed: 250ms;

  --searchbar-height: 80px;
}

.content {
  margin-top: calc(var(--searchbar-height) + 40px);
}

.spinner {
  color: var(--pardelia-primary);
}

.text-pardelia {
  color: var(--pardelia-primary);
}

.error-message-form {
  display: inline;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  font-weight: bold;
  color: var(--danger);
}

.btn-primary {
  background-color: var(--pardelia-blue) !important;
  border-color: var(--pardelia-blue) !important;
}

.btn-primary:hover {
  background-color: var(--pardelia-primary) !important;
  border-color: var(--pardelia-primary) !important;
}

.font-18 {
  font-size: 18px;
}

.field-title {
  font-weight: bold;
}

.all-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.container-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: pointer;
  color: var(--pardelia-purple);
}

.vertical-align {
  display: flex;
  text-align: center;
  align-self: center;
  align-items: center;
}

.horizotal-align {
  justify-content: center;
}

.align-right {
  justify-content: flex-end;
  color: var(--gray);
}

.attach {
  transform: rotate(45deg);
  transition: all var(--speed) ease-in-out;
}

.attach:hover {
  transform: rotate(45deg) scale(1.5);
}

.animation-expand {
  transition: all var(--speed) ease-in-out;
}

.animation-expand:hover {
  transform: scale(1.2);
}

.menu-shadow {
  box-shadow: 10px .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.empty-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-bottom: var(--gray-light) 1px solid !important;
  color: var(--gray-light);
  border-radius: 0px !important;
}

.empty-border:focus {
  box-shadow: none;
}

.more-information {
  transform-origin: top;
  height: auto;
  transition: all var(--speed);
  overflow: hidden;
  transform: scaleY(0);
}

.more-information.open {
  height: auto;
  transform: scaleY(1);
}

.more-information.close {
  width: 100%;
  height: 0;
}

.item-container {
  box-shadow: 5px 5px 20px 1px #ccc;
  border-radius: 4px;
  border-left: var(--pardelia-blue) solid 6px;
  height: min-content;
  transition: all var(--speed) ease-in-out;
}

.item-title {
  font-size: 16px;
  font-weight: 600;
}

.item-price {
  font-size: 16px;
  font-weight: 600;
}

.item-short-description {
  color: var(--gray);
  font-weight: 500;
}

.item-description {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  text-align: right;
  overflow: hidden;
  max-height: 125px;
}

.item-plus-container {
  margin: auto;
  background-color: var(--pardelia-blue);
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.item-plus {
  background-color: rosybrown;
  align-items: center;
}

.sub-item-container {
  border-top: var(--gray-light) 1px solid;
  display: flex;
  flex-wrap: wrap;
  color: var(--gray);
  flex-wrap: nowrap;
}

.btn-pardelia {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: 1px solid transparent;
}

.btn-done {
  background-color: var(--pardelia-violet-background);
  color: var(--pardelia-primary);
  font-size: 14px;
}

.btn-done:hover {
  background-color: var(--pardelia-violet);
  color: white;
  transition: all var(--speed) ease-in-out;
}

.btn-cancel {
  background-color: white;
  color: #646161;
  font-size: 14px;
  border: 1px solid var(--gray-light);
}

.btn-cancel:hover {
  background-color: var(--gray-light);
  color: white;
  transition: all var(--speed) ease-in-out;
}

.btn-float-add {
  position: fixed;
  right: 50px;
  bottom: 50px;
  border-radius: 100px;
  width: fit-content;
  padding: 5px;
  z-index: 9;
}

.empty-border-all {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-bottom: none !important;
}

.empty-border:focus {
  box-shadow: none;
}

.tag-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: nowrap;
  margin: -2px 0 !important;
  width: 100%;
  overflow: hidden;
}

.tag {
  background-color: var(--pardelia-violet-background);
  color: var(--pardelia-primary);
  padding: 8px;
  border-radius: 15px;
  font-size: 12px;
  max-width: fit-content !important;
  max-height: fit-content !important;
  flex-wrap: nowrap;
}

.tag-input {
  min-width: fit-content;
}

.col-tags {
  flex: auto;
}