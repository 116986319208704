.login-container {
    border-radius: 5px;
    border: 2px #999999 solid;
    padding: 20px;
    max-width: 500px;
    height: auto;
    margin-top: 10%;
}

input {
    border: 1px var(--pardelia-blue) solid !important;
}