.loading-template-container {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;
}

.loading-template-item-lg,
.loading-template-item-circle {
    background: rgb(233, 233, 233);
    height: 120px;
    border-radius: 10px;
    box-shadow: 15px 15px 14px 0px rgba(0, 0, 0, 0.35);
    animation-fill-mode: forwards !important;
    animation-direction: alternate !important;
    animation-name: loading;
    animation-iteration-count: infinite;
    animation-duration: 0.5s;
}

.loading-template-item-lg {
    margin: auto;
    margin-bottom: 40px;
}

.loading-template-item-lg {
    flex: 0 0 98%;
    max-width: 100%;
}

.loading-template-container-circles {
    display: flex;
    /* flex-wrap: wrap; */
    text-align: left;
    align-items: flex-start;
}

.loading-template-item-container-circle {
    margin-left: 4%;
    flex: 0 0 12.3%;
}

.loading-template-item-circle {
    width: 60px;
    height: 60px;
    border-radius: 100px;
}

@keyframes loading {
    0% {
        opacity: 0.3;
    }

    25% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.7;
    }
}