@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SF Pro Display Regular';
  src: local('SF Pro Display Regular'), url(../../static/media/SFProDisplay-Regular.1e1c0e29.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: local('SF Pro Display Bold'), url(../../static/media/SFProDisplay-Bold.0b920a09.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Italic';
  src: local('SF Pro Display Italic'), url(../../static/media/SFProDisplay-Italic.6171fd1c.ttf) format('truetype');
}

* {
  font-family: 'SF Pro Display Regular', 'PT sans', serif !important;
}

.font-bold {
  font-family: 'SF Pro Display Bold', 'PT sans', serif !important;
}

.font-italic {
  font-family: 'SF Pro Display Italic', 'PT sans', serif !important;
}

:root {
  --pardelia-primary: #7540EE !important;
  --pardelia-blue: #2F61D5 !important;
  --pardelia-violet: #956af2 !important;
  --pardelia-yellow: #cb9b4a !important;

  --pardelia-primary-medium: #6139ca !important;
  --pardelia-primary-strong: #25265E !important;

  --gray: #6c757d !important;
  --gray-light: #c0c3c5 !important;
  --gray-background: #fafafb !important;
  --danger: #dc3545;


  --pardelia-violet-background: #e4dcfc !important;


  --lateral-menu-width: 73px;
  --speed: 250ms;

  --searchbar-height: 80px;
}

.content {
  margin-top: calc(80px + 40px);
  margin-top: calc(var(--searchbar-height) + 40px);
}

.spinner {
  color: #7540EE;
  color: var(--pardelia-primary);
}

.text-pardelia {
  color: #7540EE;
  color: var(--pardelia-primary);
}

.error-message-form {
  display: inline;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  font-weight: bold;
  color: #dc3545;
  color: var(--danger);
}

.btn-primary {
  background-color: #2F61D5 !important;
  background-color: var(--pardelia-blue) !important;
  border-color: #2F61D5 !important;
  border-color: var(--pardelia-blue) !important;
}

.btn-primary:hover {
  background-color: #7540EE !important;
  background-color: var(--pardelia-primary) !important;
  border-color: #7540EE !important;
  border-color: var(--pardelia-primary) !important;
}

.font-18 {
  font-size: 18px;
}

.field-title {
  font-weight: bold;
}

.all-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.container-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: pointer;
  color: var(--pardelia-purple);
}

.vertical-align {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
  -webkit-align-items: center;
          align-items: center;
}

.horizotal-align {
  -webkit-justify-content: center;
          justify-content: center;
}

.align-right {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  color: #6c757d;
  color: var(--gray);
}

.attach {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: all 250ms ease-in-out;
  transition: all var(--speed) ease-in-out;
}

.attach:hover {
  -webkit-transform: rotate(45deg) scale(1.5);
          transform: rotate(45deg) scale(1.5);
}

.animation-expand {
  transition: all 250ms ease-in-out;
  transition: all var(--speed) ease-in-out;
}

.animation-expand:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.menu-shadow {
  box-shadow: 10px .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.empty-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-bottom: #c0c3c5 1px solid !important;
  border-bottom: var(--gray-light) 1px solid !important;
  color: #c0c3c5;
  color: var(--gray-light);
  border-radius: 0px !important;
}

.empty-border:focus {
  box-shadow: none;
}

.more-information {
  -webkit-transform-origin: top;
          transform-origin: top;
  height: auto;
  transition: all 250ms;
  transition: all var(--speed);
  overflow: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.more-information.open {
  height: auto;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.more-information.close {
  width: 100%;
  height: 0;
}

.item-container {
  box-shadow: 5px 5px 20px 1px #ccc;
  border-radius: 4px;
  border-left: #2F61D5 solid 6px;
  border-left: var(--pardelia-blue) solid 6px;
  height: -webkit-min-content;
  height: min-content;
  transition: all 250ms ease-in-out;
  transition: all var(--speed) ease-in-out;
}

.item-title {
  font-size: 16px;
  font-weight: 600;
}

.item-price {
  font-size: 16px;
  font-weight: 600;
}

.item-short-description {
  color: #6c757d;
  color: var(--gray);
  font-weight: 500;
}

.item-description {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  text-align: right;
  overflow: hidden;
  max-height: 125px;
}

.item-plus-container {
  margin: auto;
  background-color: #2F61D5;
  background-color: var(--pardelia-blue);
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.item-plus {
  background-color: rosybrown;
  -webkit-align-items: center;
          align-items: center;
}

.sub-item-container {
  border-top: #c0c3c5 1px solid;
  border-top: var(--gray-light) 1px solid;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  color: #6c757d;
  color: var(--gray);
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.btn-pardelia {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: 1px solid transparent;
}

.btn-done {
  background-color: #e4dcfc;
  background-color: var(--pardelia-violet-background);
  color: #7540EE;
  color: var(--pardelia-primary);
  font-size: 14px;
}

.btn-done:hover {
  background-color: #956af2;
  background-color: var(--pardelia-violet);
  color: white;
  transition: all 250ms ease-in-out;
  transition: all var(--speed) ease-in-out;
}

.btn-cancel {
  background-color: white;
  color: #646161;
  font-size: 14px;
  border: 1px solid #c0c3c5;
  border: 1px solid var(--gray-light);
}

.btn-cancel:hover {
  background-color: #c0c3c5;
  background-color: var(--gray-light);
  color: white;
  transition: all 250ms ease-in-out;
  transition: all var(--speed) ease-in-out;
}

.btn-float-add {
  position: fixed;
  right: 50px;
  bottom: 50px;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  z-index: 9;
}

.empty-border-all {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-bottom: none !important;
}

.empty-border:focus {
  box-shadow: none;
}

.tag-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: nowrap;
  margin: -2px 0 !important;
  width: 100%;
  overflow: hidden;
}

.tag {
  background-color: #e4dcfc;
  background-color: var(--pardelia-violet-background);
  color: #7540EE;
  color: var(--pardelia-primary);
  padding: 8px;
  border-radius: 15px;
  font-size: 12px;
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.tag-input {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.col-tags {
  -webkit-flex: auto;
          flex: auto;
}
.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--lateral-menu-width);
    text-align: center;
    background-color: var(--pardelia-primary);
    background-image: url(../../static/media/sidebar-background.d875eaed.png);
    background-size: cover;
}

.menu-item-logo {
    background-color: var(--pardelia-primary-strong);
    height: var(--searchbar-height);
}

.menu-item-container {
    height: 100%;
}

.menu-item {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.menu-item:hover {
    background-color: var(--pardelia-primary-medium);
}

.menu-item-active {
    background-color: var(--pardelia-primary-medium);
    border-right: 2.5px solid var(--pardelia-yellow);
}

.align-top {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 45%;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.align-bottom {
    height: 45%;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.searchbar-container {
    position: fixed;
    top: 0;
    left: var(--lateral-menu-width);
    background-color: var(--gray-background);
    z-index: 1;
    height: var(--searchbar-height);
    width: 100%;
}

.searchbar-right-container,
.searchbar-left-container {
    height: 100% !important;
}

.searchbar-left-container {
    width: 60%;
}

.searchbar-right-container {
    width: 32%;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.searchbar-search {
    width: 40% !important;
    background-color: var(--gray-background) !important;
    border-bottom: var(--gray-light) 1px solid !important;
}

.searchbar-profile-container {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: var(--gray);
}
.login-container {
    border-radius: 5px;
    border: 2px #999999 solid;
    padding: 20px;
    max-width: 500px;
    height: auto;
    margin-top: 10%;
}

input {
    border: 1px var(--pardelia-blue) solid !important;
}
.loading-template-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.loading-template-item-lg,
.loading-template-item-circle {
    background: rgb(233, 233, 233);
    height: 120px;
    border-radius: 10px;
    box-shadow: 15px 15px 14px 0px rgba(0, 0, 0, 0.35);
    -webkit-animation-fill-mode: forwards !important;
            animation-fill-mode: forwards !important;
    -webkit-animation-direction: alternate !important;
            animation-direction: alternate !important;
    -webkit-animation-name: loading;
            animation-name: loading;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

.loading-template-item-lg {
    margin: auto;
    margin-bottom: 40px;
}

.loading-template-item-lg {
    -webkit-flex: 0 0 98%;
            flex: 0 0 98%;
    max-width: 100%;
}

.loading-template-container-circles {
    display: -webkit-flex;
    display: flex;
    /* flex-wrap: wrap; */
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.loading-template-item-container-circle {
    margin-left: 4%;
    -webkit-flex: 0 0 12.3%;
            flex: 0 0 12.3%;
}

.loading-template-item-circle {
    width: 60px;
    height: 60px;
    border-radius: 100px;
}

@-webkit-keyframes loading {
    0% {
        opacity: 0.3;
    }

    25% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.7;
    }
}

@keyframes loading {
    0% {
        opacity: 0.3;
    }

    25% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.7;
    }
}
