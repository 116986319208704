.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--lateral-menu-width);
    text-align: center;
    background-color: var(--pardelia-primary);
    background-image: url(/src/assets/img/sidebar-background.png);
    background-size: cover;
}

.menu-item-logo {
    background-color: var(--pardelia-primary-strong);
    height: var(--searchbar-height);
}

.menu-item-container {
    height: 100%;
}

.menu-item {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.menu-item:hover {
    background-color: var(--pardelia-primary-medium);
}

.menu-item-active {
    background-color: var(--pardelia-primary-medium);
    border-right: 2.5px solid var(--pardelia-yellow);
}

.align-top {
    flex-direction: column;
    width: 100%;
    height: 45%;
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.align-bottom {
    height: 45%;
    display: flex;
    text-align: center;
    align-items: flex-end;
}