.searchbar-container {
    position: fixed;
    top: 0;
    left: var(--lateral-menu-width);
    background-color: var(--gray-background);
    z-index: 1;
    height: var(--searchbar-height);
    width: 100%;
}

.searchbar-right-container,
.searchbar-left-container {
    height: 100% !important;
}

.searchbar-left-container {
    width: 60%;
}

.searchbar-right-container {
    width: 32%;
    flex-direction: row-reverse;
}

.searchbar-search {
    width: 40% !important;
    background-color: var(--gray-background) !important;
    border-bottom: var(--gray-light) 1px solid !important;
}

.searchbar-profile-container {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: var(--gray);
}